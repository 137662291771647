import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { SuperSEO } from "react-super-seo";

const History = () => {

	const [data, setData] = useOutletContext()
	const [history, setHistory] = useState()

	useEffect(() => {
		setHistory(data.pass_data.history)
	}, [data])


	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	const getTime = (ts) => {
		if (ts){
			const options = { timeZone: "America/Denver", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleTimeString("en-US", options)
		} else {
			return "N/A"
		}
	}

	const getYear = (ts) => {
		if (ts){
			const options = { timeZone: "America/Denver", year: "numeric" };
			return new Date(ts).toLocaleString("en-US", options)
		} else {
			return "N/A"
		}
	}
	const getMonth = (ts) => {
		if (ts){
			const options = { timeZone: "America/Denver", month: "numeric" };
			return new Date(ts).toLocaleString("en-US", options)
		} else {
			return "N/A"
		}
	}
	const getMonthLong = (ts) => {
		if (ts){
			const options = { timeZone: "America/Denver", month: "long" };
			return new Date(ts).toLocaleString("en-US", options)
		} else {
			return "N/A"
		}
	}
	const getDay = (ts) => {
		if (ts){
			const options = { timeZone: "America/Denver", day: "numeric" };
			return new Date(ts).toLocaleString("en-US", options)
		} else {
			return "N/A"
		}
	}
	const getDayLong = (ts) => {
		if (ts){
			const options1 = { timeZone: "America/Denver", weekday: "long" };
			const part1 = new Date(ts).toLocaleString("en-US", options1)
			const options2 = { timeZone: "America/Denver", month: "long" };
			const part2 = new Date(ts).toLocaleString("en-US", options2)
			const options3 = { timeZone: "America/Denver", day: "numeric" };
			const part3 = new Date(ts).toLocaleString("en-US", options3)
			return `${part1} ${part2} ${getNumberWithOrdinal(part3)}`
		} else {
			return "N/A"
		}
	}

	function getNumberWithOrdinal(n) {
	  var s = ["th", "st", "nd", "rd"],
		  v = n % 100;
	  return n + (s[(v - 20) % 10] || s[v] || s[0]);
	}

	return (
		<section className="contentWrapper" id="history">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_HISTORY_TITLE}
			  description={process.env.REACT_APP_SEO_HISTORY_DESCRIPTION}
			/>

			<section className="primary">
				<h2>History</h2>
				<p>Unfortunately our history isn't complete. We are missing history from September 2021 through February 2023.</p>
				<hr />
				{history?.length ?
					<ul id="history">
					{history.map((hst, i) => {
						let newYear = false
						let newMonth = false
						let newDay = false
						let lastItem = false
						if (i !== 0 && i < history.length){
							newYear = false
							newMonth = false
							newDay = false
							lastItem = false
							const prevIndex = i - 1
							const nextIndex = i + 1
							const prevObjYear = getYear(history[prevIndex].created_at)
							const prevObjMonth = getMonth(history[prevIndex].created_at)
							const prevObjDay = getDay(history[prevIndex].created_at)
							const nextObjYear = getYear(history[nextIndex]?.created_at)
							const nextObjMonth = getMonth(history[nextIndex]?.created_at)
							const nextObjDay = getDay(history[nextIndex]?.created_at)
							const currentObjYear = getYear(hst.created_at)
							const currentObjMonth = getMonth(hst.created_at)
							const currentObjDay = getDay(hst.created_at)
							// Get new day month and year
							if (prevObjYear !== currentObjYear){
								newYear = true
							}
							if (prevObjDay !== currentObjDay){
								newDay = true
							}
							if (prevObjMonth !== currentObjMonth){
								newMonth = true
							}
							// Set last item
							if (nextObjYear !== currentObjYear){
								lastItem = true
							}
							if (nextObjDay !== currentObjDay){
								lastItem = true
							}
							if (nextObjMonth !== currentObjMonth){
								lastItem = true
							}
						} else {
							newYear = true
							newMonth = true
							newDay = true
						}

						return <>
								{newYear && <h3 className="year">{getYear(hst.created_at)}</h3>}
								{newDay && <h3 className="day">{getDayLong(hst.created_at)}</h3>}
								<li className={`status ${lastItem ? 'last' : ''}`} key={i}>
									<span className={`icon ${hst.status.toLowerCase()}`}></span>
									<h4>{getTime(hst.created_at)} - {hst.status}</h4>
									{hst.status === 'Closed' && <p><strong>Description:</strong> {hst.closure_description}</p>}

								</li>
							</>
						}
					)}

					</ul>
					:
					<p>No history available at this time</p>
				}
			</section>

			<section className="secondary">
			</section>

		</section>
	)
}

export default History