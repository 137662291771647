import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useLogout from '../hooks/useLogout';

import axios from '../api/axios';
const LOGIN_URL = 'login';

const Login = () => {

	const { auth, setAuth, persist, setPersist } = useAuth();
	const [pathname, setPathname] = useState('');

	const navigate = useNavigate();
	const location = useLocation();

	const from = pathname || "/dashboard";

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');

	const [isLoggingIn, setIsLoggingIn] = useState(false)

	const [loginError, setLoginError] = useState(false)

	const exit = useLogout();

	useEffect(() => {
		emailRef.current.focus();
		if ( location.state?.from?.pathname == "/"){
			setPathname("/dashboard")
		} else {
			setPathname(location.state?.from);
		}
	}, [])

	useEffect(() => {
		setErrMsg('');
		setLoginError(false)
	}, [email, pwd])

	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist])

	useEffect(() => {
		console.log(auth)
	}, [auth])

	const togglePersist = () => {
		setPersist(prev => !prev);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoggingIn(true)
		const dismiss = () =>  toast.dismiss(loginToast.current);
		const loginToast = toast.loading("Logging In");
		try {
			const response = await axios.post(LOGIN_URL,
				JSON.stringify({email: email, password: pwd, remember_me: persist}),
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			console.log(response?.data)
			const accessToken = response?.data?.token;
			const user = response?.data?.user;
			const roles = response?.data?.user?.role;
			const options = response?.data?.report_options
			const settings = response?.data?.user_settings
			// console.log(response?.data?.user_settings)
			setAuth({ user, roles, accessToken, options, settings });
			setEmail('');
			setPwd('');
			setIsLoggingIn(false)

			// roles === 8675309 && navigate("/user/dashboard");
			if (user?.email_verified_at){
				toast.update(loginToast, { render: 'Logged In', type: 'success', isLoading: false, autoClose: 5000});
				navigate("/");
			} else {
				await exit();
				setLoginError(true)
				toast.update(loginToast, { render: 'Email not verified', type: 'error', isLoading: false, autoClose: 5000});
			}

		} catch (err) {
			if (!err?.response){
				toast.update(loginToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('No Server Response');
			} else if ( err.response?.status === 400){
				toast.update(loginToast, { render: 'Missing username or password', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Missing username or password');
			} else if ( err.response?.status === 401){
				toast.update(loginToast, { render: 'Invalid Login', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Invalid Login');
			} else if ( err.response?.status === 403){
				toast.update(loginToast, { render: 'Login blocked by administrator', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Login blocked by administrator');
			} else {
				toast.update(loginToast, { render: 'Login Failed', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Login Failed')
			}
			{/* errRef.current.focus(); */}
		}
	}

	return (

		<article>
			{/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
			<form onSubmit={handleSubmit}>
				<label htmlFor="email">Email:</label>
				<input
					type="email"
					id="email"
					ref={emailRef}
					autoComplete="off"
					onChange={(e) => setEmail(e.target.value)}
					value={email}
					required
				/>
				<label htmlFor="password">Password:</label>
				<input
					type="password"
					id="password"
					onChange={(e) => setPwd(e.target.value)}
					value={pwd}
					required
				/>
				<button disabled={isLoggingIn ? true : false}>{isLoggingIn ? 'Logging In' : 'Sign In'}</button>
				{loginError && <p className="notVerified">Your email has not been verified. Please verify your email address from the link the the email.</p>}
				{/* <label htmlFor="persist">
				<input
					type="checkbox"
					id="persist"
					onChange={togglePersist}
					checked={persist}
				/>
				<span>Remember Me?</span>
				</label> */}
			</form>
			<p>
				<span className="forgotPassword">
					{/*put router link here */}
					<Link to="/forgot-password">Lost your password?</Link>
				</span>
			</p>
		</article>
	)
}

export default Login