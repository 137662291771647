import { useRef, useState, useEffect } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { MdPerson } from 'react-icons/md'
import { MdOutlineSettings } from 'react-icons/md'
import { MdLogout } from 'react-icons/md'
import { MdAppRegistration } from 'react-icons/md'
import { MdSpaceDashboard } from 'react-icons/md'
import { ImBullhorn } from 'react-icons/im'
import { BiDonateHeart } from 'react-icons/bi'
import { GoAlert } from 'react-icons/go'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Badge from '@mui/material/Badge';
import UserReportPopup from './UserReportPopup'
import { useMediaQuery } from 'react-responsive'

const SecondaryNavigation = ({ notifications }) => {
	const { auth } = useAuth();
	const reportOptions = auth?.options
	const passName = process.env.REACT_APP_PASS
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate()

	const [openReport, setOpenReport] = useState(false)
	const [navBack, setNavBack] = useState(true)
	const [activeCategoryID, setActiveCategoryID] = useState()
	const [activeCategoryName, setActiveCategoryName] = useState()

	const [anchorEl, setAnchorEl] = useState()
	const open = Boolean(anchorEl);

	const isMobile = useMediaQuery({
		query: '(max-width: 1100px)'
	})

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLoginSignup = () => {
		setAnchorEl(null);
		navigate('/login')
	}

	const handleProfile = () => {
		setAnchorEl(null);
		navigate('/user/profile')
	}

	const handleDashboard = () => {
		setAnchorEl(null);
		navigate('/user/dashboard')
	}

	const handleSettings = () => {
		setAnchorEl(null);
		navigate('/user/settings')
	}

	const handleDonate = () => {
		setAnchorEl(null);
		navigate('/donate')
	}

	const handleReport = () => {
		setAnchorEl(null)
		setOpenReport(true)
	}

	const exit = useLogout();

	const handleLogout = async () => {
		setAnchorEl(null);
		await exit();
	}

	const handleCloseReport = () => {
		setOpenReport(false)
	}

	const goBack = () => {
		setNavBack(true)
		setActiveCategoryID()
	}

	const parent = ( item ) => {
		setNavBack(false)
		setActiveCategoryID(item.id)
		setActiveCategoryName(item.category_name)
	}

	const report = async ( opt ) => {
		const dismiss = () =>  toast.dismiss(userReportToast.current);
		const userReportToast = toast.loading("Submitting Report");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('add-report',
			JSON.stringify({report_type: 'pass', pass_name: passName, report_category: activeCategoryName, report: opt}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(userReportToast, { render: 'Thank you for your report!', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			console.log(response.data)

			// On success
			setOpenReport(false)
			setNavBack(true)
			setActiveCategoryID()

		} catch (err) {
			if (!err?.response) {
				toast.update(userReportToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(userReportToast, { render: 'Report Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleNotifications = () => {
		setAnchorEl(null);
		navigate('/notifications')
	}

	return (
		<section id="secondaryNavigationWrapper">
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className="secondaryNavBox">
				{notifications?.length ?
					<div className="notificationWrapper">
						<Tooltip title="Notifications">
							<>
							<span className={`name importance${notifications[0].notification_importance}`} onClick={handleNotifications}>{notifications[0].notification_title}</span>
							<IconButton
								onClick={handleNotifications}
								className={`notifications ${notifications?.length ? `importance${notifications[0].notification_importance}` : ''}`}
								size="small"
								sx={{ ml: 2 }}
					  		>
								<Badge badgeContent={notifications.length} >
						 			<Avatar sx={{ width: 32, height: 32 }}><GoAlert /></Avatar>
								</Badge>
							</IconButton>
							</>
						</Tooltip>
					</div>
					:
					<div className="notificationWrapper">
					</div>
				}
				<div className="buttonWrapper">
					<Tooltip title="Donate">
						<IconButton
							onClick={handleDonate}
							size="small"
							sx={{ ml: 2 }}
					  	>
					  		<Avatar sx={{ width: 32, height: 32 }}><BiDonateHeart /></Avatar>
						</IconButton>
					</Tooltip>
					<Tooltip title="Account settings">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
			  			>
							<Avatar sx={{ width: 32, height: 32 }}><MdPerson /></Avatar>
			  		</IconButton>
					</Tooltip>
				</div>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		  	>
				{auth?.user ?
					<>
						<MenuItem onClick={handleProfile}>
							<Avatar />
							{auth?.user?.first_name} {auth?.user?.last_name}
						</MenuItem>
						<MenuItem onClick={handleDashboard}>
							<ListItemIcon>
								<MdSpaceDashboard />
							</ListItemIcon>
							Dashboard
						</MenuItem>
					</>
					:
						<MenuItem onClick={handleLoginSignup}>
							<ListItemIcon>
								<MdAppRegistration />
							</ListItemIcon>
							Login/Signup
						</MenuItem>
				}
				{auth?.user &&
					<>
						<Divider />
						<MenuItem onClick={handleSettings}>
							<ListItemIcon>
								<MdOutlineSettings />
							</ListItemIcon>
							Settings
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<MdLogout />
							</ListItemIcon>
							Logout
						</MenuItem>
					</>
			}
			</Menu>

		</section>
	)
}

export default SecondaryNavigation