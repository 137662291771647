import { SuperSEO } from "react-super-seo";

const OtherPasses = () => {
	return (
		<section className="contentWrapper" id="otherPasses">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_ROUTES_TITLE}
			  description={process.env.REACT_APP_SEO_ROUTES_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Other Passes and Routes</h2>
				<ul>
					<li><a href="https://isberthoudpassopen.com" target="_blank" rel="noreferrer">Berthoud Pass</a></li>
					<li><a href="https://iscameronpassopen.com" target="_blank" rel="noreferrer">Cameron Pass</a></li>
					<li><a href="https://iscoalbankpassopen.com" target="_blank" rel="noreferrer">Coal Bank Pass</a></li>
					<li><a href="https://isfremontpassopen.com" target="_blank" rel="noreferrer">Fremont Pass</a></li>
					<li><a href="https://ishoosierpassopen.com" target="_blank" rel="noreferrer">Hoosier Pass</a></li>
					<li><a href="https://iskenoshapassopen.com" target="_blank" rel="noreferrer">Kenosha Pass</a></li>
					<li><a href="https://islovelandpassopen.com" target="_blank" rel="noreferrer">Loveland Pass</a></li>
					<li><a href="https://islizardheadpassopen.com" target="_blank" rel="noreferrer">Lizard Head Pass</a></li>
					<li><a href="https://ismonarchpassopen.com" target="_blank" rel="noreferrer">Monarch Pass</a></li>
					<li><a href="https://ismolaspassopen.com" target="_blank" rel="noreferrer">Molas Pass</a></li>
					<li><a href="https://israbbitearspassopen.com" target="_blank" rel="noreferrer">Rabbit Ears Pass</a></li>
					<li><a href="https://isredmountainpassopen.com" target="_blank" rel="noreferrer">Red Mountain Pass</a></li>
					<li><a href="https://istennesseepassopen.com" target="_blank" rel="noreferrer">Tennessee Pass</a></li>
					<li><a href="https://isvailpassopen.com" target="_blank" rel="noreferrer">Vail Pass</a></li>
					<li><a href="https://iswolfcreekpassopen.com" target="_blank" rel="noreferrer">Wolf Creek Pass</a></li>
					<li><a href="https://isi70open.com" target="_blank" rel="noreferrer">I70 Utah to Kansas</a></li>
				</ul>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default OtherPasses