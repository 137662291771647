import { SuperSEO } from "react-super-seo";

const About = () => {
	return (
		<section className="contentWrapper" id="about">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_ABOUT_TITLE}
			  description={process.env.REACT_APP_SEO_ABOUT_DESCRIPTION}
			/>

			<section className="primary">
				<h2>About Us</h2>
				<p>It all started when founder and CEO, Tracy Francis, was living in Granby. Whenever the weather got bad people were asking how the pass was on the local Facebook groups. I, Tracy, thought there had to be a better way! I started looking into how to get the data and of course ended up at CDOT. After a number of emails and frustration I finally got the right person to talk to and came to an agreement to use their data. Once I had the data I got to work with the giant data file that CDOT gave me access to and started to make since of it and created my own simple data from that to use for the first website, <a href="https://isberthoudpassopen.com" target="_blank">isberthoudpassopen.com</a>.</p>
				<p>Things have come a long way since that first website. We now use our own internal API to give us exactly the data we need where we need it so things are faster and much more efficient. And it gives us the opportunity to customize the content more and fix glitches and bugs.</p>
				<p>These sites became my passion project. I now operate 16 websites and an iPhone app, which is currently under re-development and not available in the App Store at this time. I have lots of plans for features and improvements but as we still operate as a 1 man company with a full-time job development takes time.</p>
				<h2>Consider Donation</h2>
				<p>Drive Colorado relies solely on donations to pay for overhead, including our server and development costs. We would love to continue providing quality services to locals and tourists and your donation will allow us to do so.
				</p>
				<p>Please consider donating. Donations are accepted via PayPal.</p>
				<a className="button" rel="noreferrer" href="https://www.paypal.com/donate/?hosted_button_id=3SPWYYV34SRSC" target="_blank">Donate</a>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default About